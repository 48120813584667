
import HeaderStores from './menus/stores/HeaderStores.vue';
import Icon from 'JS/components/Icon.vue';
import MegaMenu from './menus/MegaMenu.vue';
import menuManager from '../util/menu-manager';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IHeaderJson } from 'Src/js/types/Header';

@Component({
	name: 'HeaderNavigation',
	components: {
		Icon,
		HeaderStores,
		MegaMenu,
	}
})
export default class HeaderNavigation extends Vue {

	$refs!: {
		megaMenuTrigger: HTMLButtonElement
	}

	/* Props
	============================================*/

	@Prop({ type: Object as () => Partial<IHeaderJson>, required: true })
	readonly headerData: Partial<IHeaderJson>;

	/* Data
	============================================*/

	hideMenuTimer: ReturnType<typeof setTimeout> | null;
	megaMenuShowing: boolean = false;

	/* Methods
	============================================*/

	handleMegaMenuEscape(e: KeyboardEvent) {
		if (e.key !== 'Escape' || !this.megaMenuShowing) return;
		this.hideMegaMenu();
		this.$refs.megaMenuTrigger.focus();
	}

	clearHideMenuTimer() {
		if (this.hideMenuTimer) {
			clearTimeout(this.hideMenuTimer);
		}
	}

	hideMegaMenu() {
		this.clearHideMenuTimer();
		this.hideMenuTimer = setTimeout(() => {
			this.megaMenuShowing = false;
		}, 150);
	}

	showMegaMenu() {
		menuManager.closeMenus();
		this.clearHideMenuTimer();
		this.megaMenuShowing = true;
	}
}

